/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
.cmap-div {
  display: flex;
  margin: auto;
  object-fit: contain;

  img {
    width: 100%;
    max-height: 70vh;
    margin: auto;
    object-fit: contain;
    image-rendering: -webkit-optimize-contrast;
  }
}
