/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

.g-upgrade-form-container {
  display: flex;

  .g-upgrade-form-info-container {
    display: flex;
    flex-direction: column;
    padding-right: 1rem;

    ul {
      list-style-type: disc;
      margin-left: 1.5rem;

      li {
        margin: 1rem 0;
      }
    }

    .g-upgrade-form-additional-info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-around;
      font-size: 0.875rem;
      font-style: italic;

      .error {
        color: var(--color-red-error);
      }
    }
  }

  .g-upgrade-form {
    padding-left: 1rem;

    .g-upgrade-input {
      margin-bottom: 2rem;
    }
  }
}
