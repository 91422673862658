/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
.g-esri-override {
  .g-DataLayersPanel {
    height: 100%;
    padding: 0.5rem;
    background-color: var(--surface-a);
    font-family: var(--font-family);

    .p-button.btn-add-layer {
      display: flex;
      position: relative;
      margin-top: 10px;
      margin-left: 20px;
      padding: calc(var(--widget-panel-line-margin-Y) / 2) var(--widget-panel-padding-X);
      font-size: var(--font-size_-2);
    }

    .heading {
      font-family: var(--font-family-narrow);
    }

    .g-Dialog {
      .g-Dialog-Footer {
        border-top-width: 0;
      }
    }
  }
}
