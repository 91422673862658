/* src/app/Assets/Styles/GhgSat/theme/_variables.scss  */
// Copyright 2021 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

// Theme Specific Variables

// COLORS
:root {
  --surface-a: #ffffff;
  --surface-a-rgb: 255, 255, 255;
  --surface-a-500: rgba(var(--surface-a-rgb), 0.5);
  --surface-b: #142134;
  --surface-c: #f3f3f3;
  --surface-d: #dee2e6;
  --surface-e: #e8e8e8;
  --surface-f: #e9ecef;
  --surface-g: #dddcdc;
  --border-a: #ebebeb;
  --color-text: #142134;
  --color-text-secondary: #757575;
  --color-text-input: #0f1726;
  --text-disabled: #8d8d8d;
  --color-primary: #0077dc;
  --color-primary-darker: #0164bb;
  --color-primary-100: #f6faff;
  --color-primary-lighter: #def2fa; // --color-primary-050 ?
  --color-primary-text: #ffffff;
  --color-primary-background: var(--color-orbit-blue);
  --color-primary-background-darker: var(--color-orbit-blue-darker);
  --color-orbit-blue: #1f2f79;
  --color-orbit-blue-rgb: 31, 47, 121;
  --color-orbit-blue-darker: #131e4e;
  --color-black: #000000;
  --color-black-shadow: #00000040;
  --color-black-15: hsla(219, 43%, 10%, 0.15);
  --color-black-25: hsla(219, 43%, 10%, 0.25);
  // Shades of grey
  --color-grey--50: #fafafa;
  --color-grey--100: #f3f3f3;
  --color-grey--200: #eeeeee;
  --color-grey--300: #e0e0e0;
  --color-grey--400: #bdbdbd;
  --color-grey--400-rgb: 189, 189, 189;
  --color-grey--500: #8d8d8d;
  --color-grey--600: #757575;
  --color-grey--700: #616161;
  --color-grey--700-rgb: 97, 97, 97;
  --color-grey--800: #424242;
  --color-grey--900: #212121;
  --color-grey-border: #c1c1c1;
  --color-blue-highlight: #f6faff;
  --color-info: #69cccc;
  --color-red: #ff3e3e;
  --color-red-error: #c43a0e;
  --color-red-highlight: #fad4d4;
  --color-orange-text: #b82400;
  --color-orange-highlight: #fff4e6;
  --color-red-rgb: 255, 62, 62;
  --color-yellow-rgb: 232, 167, 0;
  --color-yellow: #e8a700;
  --color-yellow--opacity-20: rgba(232, 167, 0, 0.2);
  --color-yellow-bright: #ffec3c;
  --color-green: #00a958;
  --color-green-highlight: #e1f1ea;
  --color-green-background: #b7f5c9;
  --color-green-rgb: 0, 169, 88;
  --color-purple: #4d3b60;
  --color-grey-disabled: #f0efef;
  // TYPOGRAPHY
  --color-purple-observation-rgb: 97, 85, 234;
  --color-teal-observation-rgb: 0, 163, 122;
  --font-family: "Roboto", sans-serif;
  --font-family-narrow: "Archivo Narrow", sans-serif;
  --font-family-icon: "g";
  --font-weight-normal: 400;
  --font-weight-medium: 600;
  --font-weight-bold: 700;
  --font-size_-4: 0.5rem; // 8px
  --font-size_-3: 0.625rem; // 10px
  --font-size_-2: 0.75rem; // 12px
  --font-size_-1: 0.875rem; // 14px
  --font-size__0: 1rem; //16px
  --font-size__1: 1.25rem; //20px
  --font-size__2: 22px;
  --font-size__3: 24px;
  --line-height: #{calc(7 / 6)};
  --tracking-fit: 0.015em;
  --tracking-loose: 0.025em;
  --tracking-tight: -0.005em;
  --vertical-grid-baseline: 30px;
  --side-bar-width: 14.0625vw;
  --side-bar-min-width: 300px;
  --space: 30px;
  --space-mouse: #{pxtorem(5px)};
  --space-hamster: #{pxtorem(10px)};
  --space-kitten: #{pxtorem(15px)};
  --space-puppy: #{pxtorem(20px)};
  --space-cat: #{pxtorem(30px)};
  --space-dog: #{pxtorem(60px)};
  --space_-4: calc(var(--space) / 6); // 5px
  --space_-3: calc(var(--space) / 3); // 10px
  --space_-2: calc(var(--space) / 2); // 15px
  --space_-1: calc(var(--space) / 1.5); // 20px
  --space__0: var(--space); // 30px
  --space__1: calc(var(--space) * 1.3333333); // 40px
  --space__2: calc(var(--space) * 2); // 60px
  --card-shadow:
    0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  --ui-box-shadow-Y: 0 2px 5px rgba(0, 0, 0, 0.15);
  --ui-box-shadow-Center: 0 0 5px rgba(0, 0, 0, 0.25);
}
